<template>
    <v-container fluid>
        <v-card elevation="6" outlined>
            <v-card-title>
                {{this.$t('settings.lang_pickUpNames')}}
            </v-card-title>
            <v-divider class="ma-0"/>
            <v-card-text class="ma-0 pa-0">
                <Datatable ref="nicknamesDataTable"
                           :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.PICKUPNAME"
                           :datatable-headers="datatableHeaders"
                           no-excel-export
                           @editEntry="entryEdit"
                           @deleteEntry="entryDelete"
                           @deleteEntries="entriesDelete"
                           show-delete-buttons
                           show-edit-buttons
                />
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" persistent width="500px">
            <v-card :loading="loading">
                <v-card-title class="pa-1">
                        <span class="pl-2">
                            {{$t('generic.lang_edit')}}
                        </span>
                        <v-spacer/>
                        <v-btn icon @click="dialog=false">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                </v-card-title>
                <v-divider class="ma-0 mb-2"/>
                <v-card-text class="pa-0">
                    <v-container class="pb-0">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="text" :label="$t('settings.lang_pickUpNames')" outlined
                                                  :rules="[v=>!!v|| $t('generic.lang_requiredField')]"
                                    >

                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions >
                    <v-row no-gutters >
                        <v-col align="right">
                            <v-btn @click="edit" color="success" :disabled="!valid" large :loading="loading">
                                {{$t('generic.lang_edit')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
</style>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import Datatable from "../../datatable/Datatable";
    import mixin from '../../../mixins/KeyboardMixIns'

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                dialog:false,
                valid:false,
                text: "",
                mode:"",
                loading:false,
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide:true,
                    },
                    {
                        text:this.$t('settings.lang_pickUpNames'),
                        value: "name",
                    },
                ],
            }
        },
        methods: {
            deleteData: function (idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t('settings.lang_deletePickUpName'),
                    text: this.$t('settings.lang_deletePickUpNameText'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.SETTINGS.PICKUPNAME.DELETE, {
                            pickUpNumberId: idsToDelete,
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('settings.lang_pickUpNameDeleted'),
                                    color: "success"
                                });
                                self.$refs.nicknamesDataTable.getDataFromApi();
                                self.$refs.nicknamesDataTable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
            edit(){
                if(!this.$refs.form.validate()){
                    return;
                }
                this.loading=true;
                this.axios.post(ENDPOINTS.SETTINGS.PICKUPNAME.UPDATE,{
                    pickUpNumberId:this.id,
                    pickUpNumberName:this.text,
                }).then(res=>{
                    if(res.data.status==="SUCCESS"){
                        this.text = "";
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_updatedSuccessfully'),
                            color: "success",
                        });
                    }else{
                        Events.$emit("showSnackbar", {
                            message:res.data.message,
                            color: "error",
                        });
                    }
                    }).catch(err=>{
                    Events.$emit("showSnackbar", {
                        message:err.meassage,
                        color: "error",
                    });
                }).finally(fin=>{
                    this.loading=false;
                    this.dialog=false;
                    this.$refs.form.reset()
                    this.$refs.nicknamesDataTable.getDataFromApi();
                    this.$refs.nicknamesDataTable.resetSelectedRows();
                })
            },
            entryEdit(entry) {
                this.valid=true;
                this.mode="Edit";
                this.id=entry.id;
                this.get();
                this.dialog=true;
            },
            get(){
                this.loading=true;
                this.axios.post(ENDPOINTS.SETTINGS.PICKUPNAME.GET,{
                    pickUpNumberId:this.id,
                }).then(res=>{
                        this.text=res.data.formfillData.textFields.pickUpNumberName;
                    }).catch(err=>{
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(fin=>{
                    this.loading=false;
                })
            },
            entryDelete(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            },

        },
    }
</script>