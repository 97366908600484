<template>
    <v-container class="pa-0" fluid>
        <page-title :heading="this.$t('settings.lang_pickUpNames')"
                    :subheading="''"
                    :icon="icon"
                    show-add-button
                    :permission-add="true"
        ></page-title>
        <div class="app-main__inner">
            <PickupNamesComponent/>
        </div>
    </v-container>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import PickupNamesComponent from "../../../components/settings/cashier/PickupNamesComponent";

    export default {
        name: "PickupNames",
        components: {
            PickupNamesComponent,
            PageTitle,
        },

        data: () => ({
            icon: ' pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>